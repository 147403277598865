import { useRef } from "react";
import "./ImageInput.css";
import {  AiOutlineCloudUpload } from "react-icons/ai";

const ImageInput = ({  setImage, imageName }) => {
  const fileInputRef = useRef(null);
  const handleImageInput = (event) => {
      const selectedFile = event.target.files[0];
      setImage(selectedFile);
  };
  const handleImageDrop = (event) => {
    event.preventDefault();
      const selectedFile = event.dataTransfer.files[0];
      setImage(selectedFile);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };
  return (
    <div
      className="image-input-container"
      onDrop={handleImageDrop}
      onDragOver={handleDragOver}
    >
      <div className="image-input">
          <div className="upload-icon-container">
              {imageName}
          </div>
        <div className="image-input-content">
          <button
            type="button"
            className="image-input-button border-primary gap-2"
            onClick={() => fileInputRef.current?.click()}
          >
            Upload{" "}
            <AiOutlineCloudUpload
              style={{ width: "20px", height: "20px", color: "gray" }}
            />
          </button>
        </div>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleImageInput}
      />
    </div>
  );
};

export default ImageInput;
